<template>
  <div>
    <v-subheader class="font-italic font-weight-light py-0"
      >Aggiungi Tavoli
    </v-subheader>
    <v-row class="mt-4 mb-6">
      <v-subheader class="font-italic font-weight-light">
        <v-btn class="mr-3" small fab @click="remove('standard')"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
        <v-btn class="mr-3" small fab @click="add('table-standard')"
          ><v-icon>mdi-plus</v-icon></v-btn
        >
        <StandardColorPicker v-model="colorStandardTable"></StandardColorPicker>
        <p class="ma-3">Colore Tavolo Standard</p></v-subheader
      >
    </v-row>
    <v-row class="mb-3">
      <v-subheader class="font-italic font-weight-light">
        <v-btn class="mr-3" small fab @click="remove('circular')"
          ><v-icon>mdi-minus</v-icon></v-btn
        >
        <v-btn class="mr-3" small fab @click="add('table-circular')"
          ><v-icon>mdi-plus</v-icon></v-btn
        >

        <StandardColorPicker
          :value="colorCircularTable"
          v-model="colorCircularTable"
        ></StandardColorPicker>
        <p class="ma-3">Colore Tavolo Tondo</p></v-subheader
      >
    </v-row>

    <v-expansion-panels v-model="panel" flat>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header hide-actions v-slot="{ open }">
          <v-row no-gutters>
            <v-col cols="8" class="text--secondary">
              <v-fade-transition leave-absolute>
                <span class="font-italic font-weight-light" v-if="open" key="0">
                  Applica modifiche
                </span>
                <span class="font-italic font-weight-light" v-else key="1">
                  Opzioni Tavoli
                </span>
              </v-fade-transition>
            </v-col>
          </v-row>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <!-- <v-checkbox
            hide-details
            v-model="vertical"
            label="Gestione Verticale"
            class="ml-3 font-italic font-weight-light"
          ></v-checkbox> -->
          <v-checkbox
            hide-details
            class="ml-3 font-italic font-weight-light"
            v-model="draggable"
            label="Sposta"
          ></v-checkbox>

          <v-checkbox
            hide-details
            class="ml-3 font-italic font-weight-light"
            v-model="resizable"
            label="Ridimensiona"
          ></v-checkbox>

          <v-checkbox
            hide-details
            class="ml-3 font-italic font-weight-light"
            v-model="deletable"
            label="Elimina"
          ></v-checkbox>
          <v-checkbox
            hide-details
            v-model="disabled"
            label="Abilita/Disabilita"
            class="ml-3 font-italic font-weight-light"
          ></v-checkbox>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>


<script>
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";

export default {
  components: {
    StandardColorPicker,
  },
  data() {
    return {
      colorCircularTable: "#E0E0E0",
      colorStandardTable: "#E0E0E0",
      deletable: false,
      disabled: false,
      resizable: true,
      draggable: true,
      panel:0,
      vertical: false,
    };
  },
  mounted() {},
  methods: {
    add(type) {
      this.$emit("add", type);
    },
    remove(type) {
      this.$emit("remove", type);
    },
  },
  watch: {
    deletable(newVal) {
      this.$emit("deletable", newVal);
    },
    disabled(newVal) {
      this.$emit("disable", newVal);
    },
    resizable(newVal) {
      this.$emit("resizable", newVal);
    },
    draggable(newVal) {
      this.$emit("draggable", newVal);
    },
    colorStandardTable(newVal) {
      this.$emit("change-color-standard", newVal);
    },
    colorCircularTable(newVal) {
      this.$emit("change-color-circular", newVal);
    },
  },
};
</script>

<style>
</style>