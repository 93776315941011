<template>
  <div>
    <StandardDialog
      dialog-width="40vw"
      dialog-height="calc(50vh-10px)"
      persistent
      v-model="localOpenDialog"
      :title="localTitle"
    >
      <v-row class="pa-2">
        <v-col>
          <v-subheader>
            <v-text-field
              hide-details="auto"
              solo
              dense
              label="Ospiti"
              v-model="localGuests"
            ></v-text-field>
            <p class="ma-3">Ospiti</p></v-subheader
          ></v-col
        >
        <v-col>
          <v-subheader>
            <StandardColorPicker v-model="colorTable"></StandardColorPicker>
            <p class="ma-3">Colore Tavolo</p></v-subheader
          ></v-col
        >
      </v-row>

      <template v-slot:footer-actions>
        <v-btn text color="error" @click="close">Annulla</v-btn>
        <v-btn text :disabled="!(localGuests > 0)" @click="save">Salva</v-btn>
      </template>
    </StandardDialog>
  </div>
</template>

<script>
import StandardColorPicker from "@/components/common/StandardColorPicker.vue";
import StandardDialog from "@/components/common/StandardDialog.vue";
import tableService from "@/services/bar/table.service.js";

export default {
  components: {
    StandardColorPicker,
    StandardDialog,
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false,
    },
    table: {
      type: Object,
      default: () => {},
    },
    title: {
      type: String,
      default: "",
    },
  },

  data: function () {
    return {
      localOpenDialog: undefined,
      colorTable: "",
      localTitle: "",
      localGuests: 0,
      localTable: undefined,
    };
  },
  mounted: function () {
    this.localTitle = "Dettaglio Tavolo " + this.table.i;
    this.reset();
    this.localOpenDialog = this.openDialog;
    this.localTable = this.table;
    this.colorTable = this.localTable.color;
    this.localGuests = this.localTable.guests;
  },
  methods: {
    reset() {
      this.colorTable = "";
      this.localGuests = 0;
      this.localTable = undefined;
    },
    close: function () {
      this.$emit("close");
    },
    save: function () {
      let opacity = "";
      if (this.localTable) {
        if (!this.localTable.enabled) opacity = "opacity:0.4;";

        let styleObject = {
          "background-color": this.colorTable,
          opacity: opacity,
        };
        tableService
          .update({
            id: this.localTable.id,
            guests: this.localGuests,
            color: this.colorTable,
            style: JSON.stringify(styleObject),
          })
          .then(() => {
            this.$emit("save");
          });
      }
    },
  },
};
</script>

<style>
</style>