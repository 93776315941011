import axios from "@/services/axios";
import UrlKeeper from '@/services/UrlKeeper.js'

class reservationManagement {
  list(filters) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/reservations/list", {
        filters: filters,
      }).then((response) => {
        if (response.success) 
          resolve(response.results.rows);
        else 
          reject("Error during the call: list()" + response.results.error)
      });
    });
  }

  get(reservationId) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.get(apiUrl + "/bar/reservations/get", {id: reservationId}).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: get(reservationId)" + response.results.error);
      });
    });
  }

  update(params) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/reservations/update", params).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: update(params)" + response.results.error);
      });
    });
  }

  create(reservation) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      axios.post(apiUrl + "/bar/reservations/create", reservation).then((response) => {
        if (response.success)
          resolve(response.results);
        else
          reject("Error during the call: create(reservation)" + response.results.error);
      });
    });
  }

  delete(reservation) {
    const apiUrl = UrlKeeper.getUrl()

    return new Promise((resolve, reject) => {
      if (!reservation.id) {
        reject("Reservation id undefined");
        return;
      }
      
      axios.get(apiUrl + "/bar/reservations/delete", {
        id: reservation.id,
      }).then((response) => {
        if (!response.success)
          reject(response.results);
        else
          resolve(response);
      });
    });
  }

}

export default new reservationManagement();